import { getTextByLanguage } from 'shared/utils/language-helpers'

import { i18n } from '@/locales'
import { Board } from '@/types'

export const getContentHtml = (
  data?: { body: string },
  signature?: { content: string }
): string => {
  if (!data) return ''
  return data.body + '<br><br>' + (signature ? signature.content : '')
}

export const getFromContent = (data: Board, isShowAllName: boolean = false) => {
  const enName = data?.drafterEnglishName ?? ''
  const krName = data?.drafterKoreanName ?? ''
  const firstContent = data?.drafterEmployeeTitle ?? ''
  let lastContent = getTextByLanguage(i18n.global.locale, enName, krName)
  if (isShowAllName) {
    lastContent = enName && krName ? `${krName} / ${enName}` : (enName ?? krName ?? '')
  }
  return firstContent
    ? lastContent
      ? `${firstContent} (${lastContent})`
      : firstContent
    : lastContent
}
