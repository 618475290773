<template>
  <el-dialog
    :title="$t(titlePopup)"
    v-model="isVisible"
    width="85%"
    class="h-[78vh]"
    @close="emit('refresh')"
  >
    <content-layout class="h-full">
      <template #content>
        <div v-if="!board" class="min-h-[120px] justify-center flex">
          <el-text>{{ $t('message.noData') }}</el-text>
        </div>
        <div v-else class="h-full flex flex-col border">
          <div class="mx-2 mt-2 header-pr-wrap p-2">
            <div class="custom-flex pb-2 pr-header">
              <div class="w-2/5 font-extrabold text-base">
                {{ board?.titleName }}
              </div>
            </div>
            <div class="custom-flex px-0 py-2 pr-info cursor-pointer">
              <div class="w-2/5 flex info-form">
                <div
                  class="w-5 text-center mr-1.5 rounded flex justify-center align-center"
                  :class="!isVisibleAttach ? 'bor-in' : 'bor-ac'"
                >
                  <div>{{ !isVisibleTransfer ? '+' : '-' }}</div>
                </div>
                <div class="flex align-center">
                  <strong>{{ $t('common.labels.from') }}: </strong>
                  <div class="ml-2">{{ getFromContent(board, true) }}</div>
                </div>
              </div>
              <div class="w-2/5">
                <strong>{{ $t('common.labels.transmitted') }}: </strong>
                {{ formatDate(board?.transmitYmd, FORMAT_DATE_TIME.DATE) }}
              </div>
              <div class="w-1/5"></div>
            </div>
          </div>
          <div class="mx-2">
            <!-- <AttachmentList :vms-file-id="board?.vmsFileId" /> -->
          </div>
          <div class="content-body">
            <!-- <DisplayContentHtmlCkEditor :body="contentHtml" /> -->
          </div>
        </div>
      </template>
    </content-layout>
  </el-dialog>
</template>

<script setup lang="ts">
import { computed, PropType, ref, watch } from 'vue'
import { formatDate } from 'shared/utils'
import { FORMAT_DATE_TIME } from 'shared/utils/constants'

// import AttachmentList from 'ui/src/components/AttachmentList.vue'
// import DisplayContentHtmlCkEditor from 'ui/src/components/DisplayContentHtmlCkEditor.vue'
import ContentLayout from 'ui/src/layouts/ContentLayout.vue'

import boardService from '@/services/board'
import { CommonService } from '@/services/commons'
import { getContentHtml, getFromContent } from '@/shared/utils/board'
import { useMainStore } from '@/stores/main'
import { AttachmentFile, Board, BooleanValue } from '@/types'

// Props
const props = defineProps({
  visible: Boolean,
  data: {
    type: Object as PropType<Board> | null,
    default: null,
  },
})

// Service
const commonService = new CommonService()

//Store
const mainStore = useMainStore()

//Emits
const emit = defineEmits(['close', 'refresh'])

// Watch
watch(props, ({ visible }) => {
  if (visible !== isVisible.value) {
    isVisible.value = visible
    if (visible) {
      getBoardDetail()
    }
  }
})

// Refs
const board = ref<Board>()
const attachments = ref<AttachmentFile[]>([])
const isVisible = ref<boolean>(false)
const isVisibleTransfer = ref<boolean>(false)
const isVisibleAttach = ref<boolean>(false)
const contentHtml = ref<string>()

const titlePopup = computed<string>(() => {
  return 'common.menus.notice'
})

const getBoardDetail = () => {
  mainStore.showLoading()
  boardService
    .getBoardDetail({
      boardSequence: props.data.boardSequence,
      isConfidential: props.data.confidentialFlag === BooleanValue.Yes,
    })
    .then((res) => {
      board.value = res.data
      contentHtml.value = getContentHtml({ body: res.data['contentDescription'] ?? '' })
      if (board.value?.vmsFileId) {
        commonService.getFiles(board.value?.vmsFileId).then((res) => {
          attachments.value = res.data
        })
      }
    })
    .catch(() => {
      board.value = null
    })
    .finally(() => {
      mainStore.hideLoading()
    })
}
</script>

<style lang="scss" scoped>
@import 'ui/assets/scss/_variables';
.custom-flex {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.preview-attachment {
  border: solid 1px #babcc3;
  .attachments-header {
    background-color: #eff1ff;
    .attachments-title {
      flex: 1;
    }
  }
  .attachments-list {
    .attachment-item {
      border-bottom: 1px solid #f1f1f1;
      .attachment-name {
        flex: 1;
      }
    }
  }
}

.header-pr-wrap {
  background: #f1f2f9;
  border: solid 1px #bfbfbf;
}
.bor-ac {
  border: solid 1px #3572ca;
}
.bor-in {
  border: solid 1px black;
}

.content-body {
  overflow: auto;
  height: 100%;
  .preview-body {
    padding-left: 10px;
    margin-right: 8px;
    border: none !important;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #adacac;
      border-radius: 5px;
    }
  }
}
</style>
