<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.642 7.321C13.6414 8.89008 13.0572 10.4029 12.0031 11.5651C10.949 12.7274 9.50024 13.456 7.93866 13.6093C6.37709 13.7626 4.81431 13.3297 3.55425 12.3946C2.2942 11.4596 1.42696 10.0893 1.1212 8.55033C0.815429 7.01133 1.093 5.41361 1.89992 4.06792C2.70683 2.72222 3.98542 1.72474 5.487 1.26948C6.98859 0.814214 8.60583 0.933712 10.0242 1.60473C11.4426 2.27575 12.5606 3.45032 13.161 4.9C13.4786 5.66693 13.6421 6.48891 13.642 7.319V7.321Z"
      stroke="#1C274C"
      stroke-width="1.5"
    />
    <path
      d="M9.84899 7.321C9.85075 8.13121 9.78654 8.94021 9.65699 9.74C9.54781 10.4412 9.3641 11.1288 9.10899 11.791C8.92464 12.2954 8.6464 12.7602 8.28899 13.161C8.16905 13.3028 8.02141 13.4186 7.85513 13.5013C7.68885 13.584 7.50743 13.6319 7.32199 13.642C7.13656 13.6319 6.95514 13.584 6.78886 13.5013C6.62258 13.4186 6.47494 13.3028 6.35499 13.161C5.99758 12.7602 5.71934 12.2954 5.53499 11.791C5.27992 11.1288 5.09621 10.4412 4.98699 9.74C4.73041 8.13754 4.73041 6.50446 4.98699 4.902C5.09556 4.20087 5.2786 3.51331 5.53299 2.851C5.71734 2.34664 5.99558 1.88178 6.35299 1.481C6.47302 1.33905 6.62081 1.22316 6.78727 1.14044C6.95374 1.05772 7.13537 1.00993 7.32099 1C7.50644 1.01006 7.68788 1.05792 7.85417 1.14063C8.02045 1.22335 8.16808 1.33917 8.28799 1.481C8.6454 1.88178 8.92364 2.34664 9.10799 2.851C9.3633 3.51254 9.54734 4.19943 9.65699 4.9C9.78654 5.69979 9.85075 6.50879 9.84899 7.319V7.321Z"
      stroke="#1C274C"
      stroke-width="1.5"
    />
    <path d="M1.00098 7.32098H13.642" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
  </svg>
</template>
