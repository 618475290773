<template>
  <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_5_419"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="23"
    >
      <path d="M19.551 0H0V22.143H19.551V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_5_419)">
      <path
        d="M13.747 17.715L13.311 17.995C13.0976 18.1319 12.8579 18.2226 12.6074 18.2614C12.3568 18.3002 12.101 18.2862 11.8561 18.2203C11.6113 18.1544 11.383 18.038 11.1858 17.8787C10.9886 17.7193 10.8269 17.5205 10.711 17.295C10.6549 17.1966 10.5732 17.1153 10.4746 17.0595C10.376 17.0037 10.2642 16.9755 10.151 16.978C9.99683 16.9784 9.84763 17.0325 9.72888 17.1308C9.61013 17.2291 9.5292 17.3656 9.49999 17.517C9.074 19.0275 9.05814 20.6243 9.454 22.143H9.76C11.368 22.106 12.94 21.6598 14.3277 20.8465C15.7153 20.0333 16.8729 18.8798 17.691 17.495C17.0761 17.1688 16.3837 17.017 15.6887 17.0559C14.9937 17.0949 14.3226 17.3232 13.748 17.716"
        fill="#FEFEFE"
      />
      <path
        d="M9.10901 11.075H6.64301C7.61666 12.6087 7.95488 14.4612 7.586 16.24L6.539 21.479C7.03509 21.6794 7.54906 21.8325 8.07401 21.936C7.94156 21.2752 7.87457 20.6029 7.87401 19.929C7.87627 18.9665 8.00744 18.0087 8.26401 17.081C8.36336 16.6481 8.60151 16.2595 8.94217 15.9744C9.28283 15.6894 9.70733 15.5235 10.151 15.502C10.4865 15.5054 10.8146 15.6004 11.1001 15.7766C11.3856 15.9528 11.6176 16.2037 11.771 16.502C11.8091 16.5809 11.8637 16.6505 11.9313 16.7063C11.9988 16.762 12.0776 16.8025 12.1622 16.8249C12.2469 16.8473 12.3354 16.8512 12.4216 16.8362C12.5079 16.8212 12.5899 16.7877 12.662 16.738L13.098 16.45C13.87 15.9205 14.7754 15.6189 15.7108 15.5798C16.6461 15.5407 17.5735 15.7657 18.387 16.229C18.8248 15.2897 19.1397 14.2978 19.324 13.278H18.914C18.331 13.2354 17.7786 13.0008 17.3431 12.6109C16.9076 12.221 16.6135 11.6978 16.507 11.123V11.079H10.407"
        fill="#FEFEFE"
      />
      <path
        d="M17.892 11.075C17.9883 11.2726 18.1355 11.4411 18.3185 11.563C18.5014 11.685 18.7135 11.7561 18.933 11.769H19.519C19.519 11.533 19.551 11.304 19.551 11.069L17.892 11.075Z"
        fill="#FEFEFE"
      />
      <path
        d="M5.30001 11.51C5.20901 11.37 5.10001 11.222 5.00701 11.075H9.39355e-06C-0.0420606 13.0362 0.426381 14.9747 1.35939 16.7003C2.29239 18.4259 3.65791 19.8793 5.32201 20.918L6.32201 15.938C6.47612 15.208 6.47704 14.454 6.3247 13.7236C6.17237 12.9933 5.87009 12.3025 5.43701 11.695L5.30001 11.51Z"
        fill="#FEFEFE"
      />
      <path
        d="M10.227 11.068V7.705H11.318C12.3319 7.63632 13.2777 7.17051 13.9502 6.4087C14.6228 5.64688 14.9676 4.65055 14.91 3.636C14.9142 3.57023 14.9054 3.50428 14.884 3.44194C14.8626 3.3796 14.8291 3.32211 14.7854 3.27276C14.7418 3.22341 14.6887 3.18319 14.6295 3.1544C14.5702 3.1256 14.5058 3.10882 14.44 3.105H12.881C12.2197 3.1233 11.5767 3.32644 11.025 3.6914C10.4732 4.05637 10.0346 4.56858 9.75901 5.17C9.4834 4.56858 9.04485 4.05637 8.49307 3.6914C7.94129 3.32644 7.29832 3.1233 6.63701 3.105L5.07801 3.105C5.0124 3.10907 4.94822 3.12604 4.88917 3.15495C4.83012 3.18385 4.77735 3.22411 4.73388 3.27343C4.69041 3.32276 4.6571 3.38017 4.63585 3.44238C4.61459 3.50459 4.60581 3.57039 4.61001 3.636C4.55237 4.65022 4.89698 5.64627 5.56907 6.40803C6.24115 7.16979 7.1865 7.63582 8.20001 7.705H9.29101V11.065L10.227 11.068Z"
        fill="#FEFEFE"
      />
    </g>
  </svg>
</template>
