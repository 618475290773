import { SubjectAbility } from 'shared/types/ability'
import { defineAsyncComponentWithOptions } from 'shared/utils'
import { KainosMessageByModule } from 'shared/utils/constants'
import layouts from 'shared/utils/layout'

const eproBidSupplierBidList = defineAsyncComponentWithOptions(
  () => import('eproBid/SupplierList.vue')
)

const eproBidRouter = [
  {
    path: '/epro/bid/supplier/bid-list',
    name: SubjectAbility.EPRO_BID_SUPPLIER_BID_LIST,
    component: eproBidSupplierBidList,
    meta: {
      layout: layouts.navLeft,
      auth: true,
      title: 'eproBidSupplierBidList',
      locales: [KainosMessageByModule.EPRO, KainosMessageByModule.COMMON],
      breadCrumbs: [
        { text: 'epro' },
        { text: 'eproBidForSupplier' },
        { text: 'eproBidSupplierBidList', isActive: true },
      ],
    },
  },
]

export default eproBidRouter
