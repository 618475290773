<template>
  <div class="footer-container flex justify-between align-center">
    <div class="menu">
      <div
        class="menu-item"
        v-for="menu of mockupData.footer.menus"
        @click="handleClickMenu(menu.key)"
      >
        {{ $t(menu.label) }}
      </div>
    </div>
    <div class="combo-box flex items-center">
      <div class="flex">
        <img width="50" class="image-logo mr-5" src="ui/assets/images/logo.png" alt="logo" />
        <div>
          <p>2023 HMM Co., Ltd. All Rights Reserved.</p>
        </div>
      </div>
      <div>
        <el-dropdown>
          <el-button class="el-dropdown-link">
            Family Site
            <Icon
              icon="iconamoon:arrow-down-2-thin"
              width="20"
              height="20"
              class="text-black ml-2"
            />
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="item of mockupData.footer.cbBox" @click="goTo(item.url)">
                {{ $t(item.label) }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
  <Membership :visible="isShowMembership" @close="isShowMembership = false" />
  <ISO :visible="isShowISO" @close="isShowISO = false" />
  <Guide :visible="isShowGuide" @close="isShowGuide = false" />
</template>

<script setup lang="ts">
import { ref } from 'vue'

import Guide from '@/views/components/LoginModal/Guide.vue'
import ISO from '@/views/components/LoginModal/Iso.vue'
import Membership from '@/views/components/LoginModal/Membership.vue'
import mockupData from '@/views/pages/mockup.json'

const isShowMembership = ref(false)
const isShowISO = ref(false)
const isShowGuide = ref(false)

const handleClickMenu = (key: string) => {
  switch (key) {
    case 'F1':
      isShowMembership.value = true
      break
    case 'F2':
      isShowISO.value = true
      break
    case 'F3':
      isShowGuide.value = true
      break
    case 'F4':
      //window.open('/support/board/notice', '_blank')
      window.location.href = '/support/board/notice'
      break
    case 'F5':
      //window.open('/ePro/main/Contact', '_blank')
      window.location.href = '/ePro/main/Contact'
      break
    case 'F6':
      //window.open('/ePro/main/Location', '_blank')
      window.location.href = '/ePro/main/Location'
      break
    default:
      break
  }
}

const goTo = (url: string) => {
  window.open(url, '_blank')
}
</script>
<style lang="scss" scoped>
.footer-container {
  background-color: #f0f0f0;
  .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    padding: 14px 0 14px 5%;
    background-color: #f0f0f0;
    color: #555;

    .menu-item {
      cursor: pointer;
    }

    .menu-item + .menu-item:before {
      content: '/';
      position: absolute;
      margin-left: -20px;
      font-weight: 200;
    }
  }

  .combo-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 5px 20px;
    color: #777;
  }
}
</style>
