<template>
  <el-dialog
    v-model="isVisible"
    class="!min-w-[850px] !max-w-950px] customLoginDialog body-no-padding"
    align-center
    style="height: 85%"
    @close="handleClose"
  >
    <template #header>
      <div class="custom-dialog-header">Membership Process</div>
    </template>
    <img
      v-if="lang === languageTypeCode.EN"
      src="@/assets/images/membership-process-en.png"
      alt="membership-process"
      class="w-full"
    />
    <img
      v-else
      src="@/assets/images/membership-process-kr.png"
      alt="membership-process"
      class="w-full"
    />
    <template #footer>
      <div class="footer-bg flex items-center">
        <div class="w-3/4 text-left" v-if="lang === languageTypeCode.EN">
          <p class="text-[14px] font-extrabold">DOCUMENT EVIDENCE</p>
          <p class="text-[14px]">
            Apply cooperation Enterprise on line, Go through the prescribed formalities based on the
            information inputted, enroll to cooperation enterprise.
          </p>
        </div>
        <div class="w-3/4 text-left" v-else>
          <p class="text-[14px] font-extrabold">DOCUMENT EVIDENCE</p>
          <p class="text-[14px]">
            사업자 등록증, 각종 인허가 서류, 통장사본, 인감증명서, 사용인감계 계좌등록 양식 등
            입니다.
          </p>
        </div>
        <div class="w-1/4">
          <el-button type="secondary" @click="handleClose"
            >{{ $t('common.buttons.close') }}
          </el-button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { languageTypeCode } from 'shared/types'

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  lang: {
    type: String,
    required: true,
    default: languageTypeCode.EN,
  },
})

// Emits
const emit = defineEmits(['submit', 'close'])

// Refs
const isVisible = ref<boolean>(false)

// Watch
watch(
  () => props.visible,
  (newVal) => {
    isVisible.value = newVal
  }
)

// Functions
const emitEvents = () => {
  emit('submit')
  emit('close')
}

const handleClose = () => {
  emitEvents()
}
</script>

<style lang="scss" scoped>
.footer-bg {
  background-color: #eaeaea;
  padding: 8px 15px;
}

.custom-dialog-header {
  font-size: 20px;
  font-weight: 900;
  line-height: 22px;
  text-transform: uppercase;
}
</style>
