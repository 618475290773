<template>
  <el-button :className="className" @click="emit('click')">
    <template #default>
      <div class="custom-tab-control" :class="{ action: isAction }">
        {{ props.title }}
      </div>
    </template>
  </el-button>
</template>

<script setup lang="ts">
const emit = defineEmits(["click"]);

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  isAction: {
    type: Boolean,
    default: false,
  },
  className: {
    type: String,
    default: "",
  },
});
</script>

<style lang="scss" scoped>
@import "ui/assets/scss/_variables";
:deep(.el-button + .el-button) {
  margin-left: 0px;
}
.custom-tab-control {
  border-style: solid;
  border-width: 1px;
  border-color: #cbcbcb;
  border-radius: 5px 5px 0 0;
  color: #5e5e5e;
  padding: 7px 20px;
  font-size: 0.95rem;
  letter-spacing: -0.5px;
  background: #efefef;
}
.custom-tab-control.action {
  border-color: $text-color-accent;
  color: #000000;
  font-weight: bold;
  background-color: #fff;
  box-shadow: inset 0 3px 0 #ee312f;
  border-bottom: 1px solid #fff;
}
.custom-tab-control.action::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ec3834;
  top: 1px;
  left: 50%;
  margin-left: -4px;
}
button {
  position: relative;
}
button:has(.custom-tab-control.action) + button:has(.custom-tab-control) {
  margin-left: 0px;
}
</style>
