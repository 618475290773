<template>
  <div
    class="px-2 rounded transition-all duration-200 cursor-pointer hover:bg-slate-100"
    :class="{ '!bg-[#eff1ff] !my-2': subMenuOpen }"
  >
    <div class="py-2 flex flex-col" :class="{ 'border-b': !last }">
      <div
        v-if="menuItems?.length"
        class="flex items-center"
        :tabindex="0"
        @click="handleOnSubMenuOpen"
        @keypress.enter="handleOnSubMenuOpen"
      >
        <div :class="{ active: subMenuOpen }">{{ name }}</div>
        <el-icon
          v-if="menuItems && menuItems.length"
          class="transition-all ml-auto cursor-pointer"
          :class="{
            'transform rotate-90': subMenuOpen,
          }"
          name="el-icon-arrow-down"
        >
          <Icon icon="mingcute:right-line" />
        </el-icon>
      </div>
      <a
        v-else-if="isExternalLink(href)"
        :href="href"
        target="_blank"
        rel="noopener noreferrer"
        class="flex items-center"
      >
        <component :is="icon" class="h-10" />
        <span class="flex items-center w-full">{{ name }}</span>
      </a>
      <div v-else class="flex items-center">
        <router-link :to="href" class="flex items-center w-full">
          {{ name }}
        </router-link>
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="subMenuOpen">
        <div v-for="(menu, index) in menuItems" :key="index" class="item flex flex-col pl-2">
          <div class="flex items-center py-2 !pl-2">
            <router-link :to="menu.path" class="hover:underline cursor-pointer text-sm w-full">
              {{ menu.name }}
            </router-link>
            <el-badge
              class="custom-badge"
              :value="unreadCounts[getKeyMenu(menu.key)]"
              :max="99"
              :show-zero="false"
            />
          </div>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { useCommonStore } from '@/stores/commons'

import type { Menu } from './type'

defineProps<{
  name: string
  href: string
  icon: object
  current: boolean
  menuItems?: Menu[]
  last?: boolean
  refresh?: boolean
}>()

const commonStore = useCommonStore()

const unreadCounts = computed(() => commonStore.unreadCounts)

const subMenuOpen = ref(false)
const handleOnSubMenuOpen = () => {
  subMenuOpen.value = !subMenuOpen.value
}

const isExternalLink = (href: string) => {
  return href && (href.startsWith('http://') || href.startsWith('https://'))
}

const getKeyMenu = (key: string) => {
  return key.toLowerCase().replace(/_./g, (match) => match.charAt(1).toUpperCase())
}
</script>

<style lang="scss" scoped>
@import 'ui/assets/scss/_variables';
[data-old-padding-top] {
  transition-duration: 0.2s;
}
a {
  color: #333 !important;
  text-decoration: none;
}

:deep(.custom-badge .el-badge__content) {
  background-color: $text-color-accent;
  color: white;
  font-size: 11px;
  font-weight: bold;
  width: 23px;
  height: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  top: 6px;
}
</style>
