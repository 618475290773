import { defineAsyncComponentWithOptions } from 'shared/utils'
import { KainosMessageByModule } from 'shared/utils/constants'
import layouts from 'shared/utils/layout'

const VesselInformation = defineAsyncComponentWithOptions(
  () => import('eproVesselInfo/VesselInformation.vue')
)

const eproVesselInfoRouter = [
  {
    path: '/epro/vessel-info/vessel-info-list',
    name: 'vesselInformation',
    component: VesselInformation,
    meta: {
      auth: true,
      layout: layouts.navLeft,
      searchable: true,
      title: 'vsselInformation',
      locales: [KainosMessageByModule.EWMS, KainosMessageByModule.COMMON],
      breadCrumbs: [
        { text: 'epro' },
        { text: 'vsselInformation' },
        { text: 'vsselInformation', isActive: true },
      ],
    },
  },
]

export default eproVesselInfoRouter
