<template>
  <el-descriptions :column="2" border class="overflow-auto">
    <el-descriptions-item label-align="right">
      <template #label>Supplier Code</template>
      <el-input v-model="form.supplierCode" readonly />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Supplier Name</template>
      <el-input v-model="form.supplierName" readonly />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Business</template>
      <el-select v-model="form.business" filterable class="flex-1" disabled>
        <el-option
          v-for="item in businessOptions"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        />
      </el-select>
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Business Type</template>
      <el-select v-model="form.businessType" filterable class="flex-1" disabled>
        <el-option
          v-for="item in businessTypeOptions"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        />
      </el-select>
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Business Kind</template>
      <el-input v-model="form.businessKind" readonly />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Business Item</template>
      <el-input v-model="form.businessItem" readonly />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Certificate Resident</template>
      <el-input v-model="form.certificateResident" readonly />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>nation</template>
      <el-input v-model="form.nation" readonly />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>State</template>
      <el-input v-model="form.state" readonly />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>City</template>
      <el-input v-model="form.city" readonly />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Location Code</template>
      <el-input v-model="form.locationCode" readonly />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Zip Code</template>
      <el-input v-model="form.zipCode" readonly />
    </el-descriptions-item>
    <el-descriptions-item span="2" label-align="right">
      <template #label>Address</template>
      <el-input v-model="form.address" readonly />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>The Previous Year's Revenue</template>
      <el-input v-model="form.yearRevenue" readonly />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Total Employee</template>
      <el-input v-model="form.totalEmployee" readonly />
    </el-descriptions-item>
    <el-descriptions-item span="2" label-align="right">
      <template #label>Major Trading Partner</template>
      <el-input v-model="form.majorTradingPartner" readonly />
    </el-descriptions-item>
    <el-descriptions-item span="2" label-align="right">
      <template #label>Supply Item</template>
      <div class="flex justify-between w-full">
        <el-select v-model="form.supplierItem.supplierItemFirst" filterable disabled class="flex-1">
          <el-option
            v-for="item in supplyItems"
            :key="item.vmsCommonCode"
            :label="item.vmsCommonName"
            :value="item.vmsCommonCode"
          />
        </el-select>

        <el-select
          v-model="form.supplierItem.supplierItemSecond"
          filterable
          class="flex-1 mx-4"
          disabled
        >
          <el-option
            v-for="item in supplyItems"
            :key="item.vmsCommonCode"
            :label="item.vmsCommonName"
            :value="item.vmsCommonCode"
          />
        </el-select>

        <el-select v-model="form.supplierItem.supplierItemThird" filterable class="flex-1" disabled>
          <el-option
            v-for="item in supplyItems"
            :key="item.vmsCommonCode"
            :label="item.vmsCommonName"
            :value="item.vmsCommonCode"
          />
        </el-select>
      </div>
    </el-descriptions-item>
  </el-descriptions>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue'
import { isEmpty } from 'lodash'

import { businessOptions, businessTypeOptions } from '@/shared/constants'
import { CompanyDetailForm, DEFAULT_VALUE } from '@/types'

const props = defineProps({
  form: {
    type: Object as PropType<CompanyDetailForm>,
    default: () => null,
  },
  supplyItems: {
    type: Object,
    default: () => null,
  },
})

// Computed
const supplyItems = computed(() => {
  const defaultOption = { vmsCommonCode: DEFAULT_VALUE.ALL, vmsCommonName: 'All' }
  return [defaultOption, ...(!isEmpty(props.supplyItems) ? props.supplyItems.data : [])]
})
</script>
