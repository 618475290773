<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_40_456)">
      <path
        d="M9.30086 17.783C14.0644 17.783 17.9261 13.9522 17.9261 9.22661C17.9261 4.50105 14.0644 0.670227 9.30086 0.670227C4.53729 0.670227 0.675659 4.50105 0.675659 9.22661C0.675659 13.9522 4.53729 17.783 9.30086 17.783Z"
        stroke="#231F20"
        stroke-width="1.2"
        stroke-miterlimit="10"
      />
      <path
        d="M16.1019 16.3532L19.874 19.883"
        stroke="#231F20"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M9.39086 9.43882C10.5281 9.43882 11.4515 8.52287 11.4515 7.39467C11.4515 6.26648 10.5281 5.35052 9.39086 5.35052C8.2536 5.35052 7.33027 6.26648 7.33027 7.39467C7.33027 8.52287 8.2536 9.43882 9.39086 9.43882ZM13.5233 12.8569C13.5233 13.5383 12.8364 13.5383 12.8364 13.5383H5.94529C5.94529 13.5383 5.25842 13.5383 5.25842 12.8569C5.25842 12.1755 5.94529 10.1202 9.39086 10.1202C12.8364 10.1202 13.5233 12.1644 13.5233 12.8569Z"
        fill="black"
        stroke="#231F20"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_40_456">
        <rect width="21" height="21" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
