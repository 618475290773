<template>
  <el-dialog
    v-model="isVisible"
    :class="
      localLang === languageTypeCode.EN
        ? '!min-w-[850px] !max-w-[950px] customLoginDialog'
        : '!min-w-[850px] !max-w-[950px] customLoginDialog footer-d-block'
    "
    @close="handleClose"
  >
    <template #header>
      <div
        class="custom-dialog-header"
        v-if="localLang === languageTypeCode.EN"
        @click="handleToggleLang"
      >
        <span class="w-2/3">NOTIFICATION OF CERTIFICATION TO ISO 50001</span>
        <span class="w-1/3 flex items-center justify-end cursor-pointer mr-7"
          ><globalIcon /> <span class="uppercase text-[12px] ml-2">Korean</span>
        </span>
      </div>
      <div class="custom-dialog-header" v-else @click="handleToggleLang">
        <span class="w-2/3">ISO 50001 인증 획득 안내</span>
        <span class="w-1/3 flex items-center justify-end cursor-pointer mr-7"
          ><globalIcon /> <span class="uppercase text-[12px] ml-2">English</span>
        </span>
      </div>
    </template>
    <div class="text-[14px] font-bold">
      <p class="flex items-start mb-3">
        <span>1.</span>
        <span class="break-words" v-if="localLang === languageTypeCode.EN">
          As we are the company carrying out the ISO 50001 standards, the amount and the efficiency
          of energy will be evaluated when we place orders for energy services, products, equipment
          having an effect on or have a possibility to be in the use of important energy in
          accordance with the company policy.
        </span>
        <span v-else>
          HMM은 ISO 50001 (에너지경영시스템) 인증 획득 업체로 중요 에너지 이용에 영향을 주거나 줄 수
          있는 에너지 서비 스, 제품, 장비를 구매할 때, 당사 절차에 의거 에너지 사용량 및 효율이 평가
          됩니다.
        </span>
      </p>
      <p class="flex items-start">
        <span>2.</span>
        <span class="break-words" v-if="localLang === languageTypeCode.EN">
          We can also ask for the additional data to evaluate the amount and the efficiency of the
          energy during the planned or expected life time when we place orders for products,
          equipment, services which are anticipated to give a significant effect on energy
          performance.
        </span>
        <span v-else>
          에너지성과에 중대한 영향을 준다고 예상되는 에너지를 이용하는 제품, 장비, 서비스를 구매할
          때, 계획된 또는 기대되는 운전 생애기간 동안 에너지 이용, 사용량 및 효율을 평가하는 추가
          자료를 요청할 수 있습니다.
        </span>
      </p>
    </div>
    <template #footer>
      <el-button type="secondary" @click="handleClose" v-if="localLang === languageTypeCode.EN"
        >{{ $t('common.buttons.close') }}
      </el-button>
      <div v-else>
        <hr class="my-4" />
        <div class="p-4">
          <div class="flex items-end text-left mb-3">
            <div class="w-1/3">
              <isoFooterIcon />
            </div>
            <div class="w-2/3">
              <p class="text-[18px] mb-3">ISO 50001 이란?</p>
              <p class="text-[14px] font-bold">
                조직이 에너지 효율, 이용 및 사용량을 포함한 에너지성과를 개선하기 위하여 필요한
                시스템과 프로세스를 수립합니다. 체계적인 에너지관리를 통하여 온실가스 배출량, 에너지
                비용 및 그 밖 의 관련된 환경영향을 저감. 지역적, 문화적, 사회적 조건에 무관하게 모든
                종류 및 규모의 조직에 적용 될 수 있습니다.
              </p>
            </div>
          </div>
          <el-button type="secondary" @click="handleClose"
            >{{ $t('common.buttons.close') }}
          </el-button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref, shallowRef, watch } from 'vue'
import { languageTypeCode } from 'shared/types'

import GlobalIcon from '@/components/icons/GlobalIcon.vue'
import IsoFooterIcon from '@/components/icons/IsoFooterIcon.vue'

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  lang: {
    type: String,
    required: true,
    default: languageTypeCode.EN,
  },
})

// Emits
const emit = defineEmits(['submit', 'close'])

// Refs
const isVisible = ref<boolean>(false)
const localLang = ref<string>(props.lang)

// Icons
const isoFooterIcon = shallowRef(IsoFooterIcon)
const globalIcon = shallowRef(GlobalIcon)

// Watch
watch(
  () => props.visible,
  (newVal) => {
    isVisible.value = newVal
  }
)

watch(
  () => props.lang,
  (newLang) => {
    localLang.value = newLang
  }
)

// Functions
const emitEvents = () => {
  emit('submit')
  emit('close')
}

const handleClose = () => {
  emitEvents()
}

const handleToggleLang = () => {
  localLang.value =
    localLang.value === languageTypeCode.EN ? languageTypeCode.KR : languageTypeCode.EN
}
</script>

<style lang="scss" scoped>
.footer-bg {
  background-color: #eaeaea;
  padding: 8px 15px;
}

.custom-dialog-header {
  font-size: 20px;
  font-weight: 900;
  line-height: 22px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
