import { SubjectAbility } from 'shared/types/ability'
import { defineAsyncComponentWithOptions } from 'shared/utils'
import { KainosMessageByModule } from 'shared/utils/constants'
import layouts from 'shared/utils/layout'

const BoardNotice = defineAsyncComponentWithOptions(() => import('board/Notice.vue'))
const BoardFAQ = defineAsyncComponentWithOptions(() => import('board/FAQ.vue'))
const BoardNews = defineAsyncComponentWithOptions(() => import('board/News.vue'))
const BoardQA = defineAsyncComponentWithOptions(() => import('board/QA.vue'))

const boardRoutes = [
  {
    path: '/support/board/notice',
    name: SubjectAbility.SUPPORT_BOARD_NOTICE,
    component: BoardNotice,
    meta: {
      layout: layouts.navLeft,
      auth: true,
      title: 'notice',
      isSpecial: true,
      locales: [
        KainosMessageByModule.EPRO,
        KainosMessageByModule.COMMON,
        KainosMessageByModule.CREW,
        KainosMessageByModule.MAIL,
        KainosMessageByModule.APPROVAL,
      ],
      breadCrumbs: [{ text: 'ePro' }, { text: 'board' }, { text: 'notice', isActive: true }],
    },
  },
  {
    path: '/support/board/faq',
    name: SubjectAbility.SUPPORT_BOARD_FAQ,
    component: BoardFAQ,
    meta: {
      layout: layouts.navLeft,
      auth: true,
      title: 'faq',
      isSpecial: true,
      locales: [
        KainosMessageByModule.EPRO,
        KainosMessageByModule.COMMON,
        KainosMessageByModule.CREW,
        KainosMessageByModule.MAIL,
        KainosMessageByModule.APPROVAL,
      ],
      breadCrumbs: [{ text: 'ePro' }, { text: 'board' }, { text: 'faq', isActive: true }],
    },
  },
  {
    path: '/support/board/news',
    name: SubjectAbility.SUPPORT_BOARD_NEWS,
    component: BoardNews,
    meta: {
      layout: layouts.navLeft,
      auth: true,
      title: 'news',
      isSpecial: true,
      locales: [
        KainosMessageByModule.EPRO,
        KainosMessageByModule.COMMON,
        KainosMessageByModule.CREW,
        KainosMessageByModule.MAIL,
        KainosMessageByModule.APPROVAL,
      ],
      breadCrumbs: [{ text: 'ePro' }, { text: 'board' }, { text: 'news', isActive: true }],
    },
  },
  {
    path: '/support/board/qa',
    name: SubjectAbility.SUPPORT_BOARD_QA,
    component: BoardQA,
    meta: {
      layout: layouts.navLeft,
      auth: true,
      title: 'qa',
      isSpecial: true,
      locales: [
        KainosMessageByModule.EPRO,
        KainosMessageByModule.COMMON,
        KainosMessageByModule.CREW,
        KainosMessageByModule.MAIL,
        KainosMessageByModule.APPROVAL,
      ],
      breadCrumbs: [{ text: 'ePro' }, { text: 'board' }, { text: 'qa', isActive: true }],
    },
  },
]

export default boardRoutes
