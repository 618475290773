import { shallowRef } from 'vue'
import { SubjectAbility } from 'shared/types/ability'

import CrewIcon from '@/assets/icons/crew.svg?component'
import DocumentIcon from '@/assets/icons/document.svg?component'
import HomeIcon from '@/assets/icons/home.svg?component'
import MaintenanceIcon from '@/assets/icons/maintenance.svg?component'
import VOIcon from '@/assets/icons/operation.svg?component'
import ProcurementIcon from '@/assets/icons/procurement.svg?component'
import SafetyIcon from '@/assets/icons/safety.svg?component'
import WageIcon from '@/assets/icons/wages.svg?component'
import EwmsIcon from '@/assets/icons/wages.svg?component'
import EproIcon from '@/assets/icons/wages.svg?component'
import { SLIDE_BARS } from '@/shared/constants'

const { approval, teamMail, userInformation, vesselBudget, commonCode } = SLIDE_BARS

type Menu = {
  name: string
  key: SubjectAbility
  refresh?: boolean
  icon?: object
  path?: string
  current?: boolean
  children?: Menu[]
}

export const menuSideBars: Menu[] = [
  {
    key: SubjectAbility.HOME,
    name: 'home',
    icon: shallowRef(HomeIcon),
    current: false,
    children: [
      {
        key: SubjectAbility.MAIN,
        path: '/dashboard',
        name: 'main',
      },
      {
        key: SubjectAbility.USER_INFORMATION,
        name: userInformation.userInformation,
        path: '/user-information',
      },
      {
        key: SubjectAbility.VESSEL_BUDGET,
        name: vesselBudget.vesselBudget,
        path: '/vessel-budget',
      },
      {
        key: SubjectAbility.COMMON_CODE,
        name: commonCode.commonCode,
        children: [
          {
            key: SubjectAbility.COMMON_CODE_VESSEL_CODE,
            name: commonCode.vesselCode,
            path: '/common-code/vessel-code',
          },
          {
            key: SubjectAbility.COMMON_CODE_PORT_CODE,
            name: commonCode.portCode,
            path: '/common-code/port-code',
          },
          {
            key: SubjectAbility.COMMON_CODE_COMPANY_CODE,
            name: commonCode.companyCode,
            path: '/common-code/company-code',
          },
          {
            key: SubjectAbility.COMMON_CODE_DEPARTMENT_CODE,
            name: commonCode.departmentCode,
            path: '/common-code/department-code',
          },
          {
            key: SubjectAbility.COMMON_CODE_MASTER_CODE,
            name: commonCode.masterCode,
            path: '/common-code/master-code',
          },
          {
            key: SubjectAbility.COMMON_CODE_CREW_CODE,
            name: commonCode.crewCode,
            path: '/common-code/crew-code',
          },
          {
            key: SubjectAbility.COMMON_CODE_SAFETY_CODE,
            name: commonCode.safetyCode,
            path: '/common-code/safety-code',
          },
        ],
      },
      {
        key: SubjectAbility.TEAM_MAIL,
        refresh: true,
        name: teamMail.teamMail,
        children: [
          { path: '/team-mail/inbox', name: teamMail.inbox, key: SubjectAbility.TEAM_MAIL_INBOX },
          { path: '/team-mail/sent', name: teamMail.sent, key: SubjectAbility.TEAM_MAIL_SENT },
          {
            path: '/team-mail/unregistered-mail',
            name: teamMail.unregisteredMail,
            key: SubjectAbility.TEAM_MAIL_UNREGISTERED_MAIL,
          },
          { path: '/team-mail/saved', name: teamMail.saved, key: SubjectAbility.TEAM_MAIL_SAVED },
          {
            path: '/team-mail/deleted',
            name: teamMail.deleted,
            key: SubjectAbility.TEAM_MAIL_DELETED,
          },
          {
            path: '/team-mail/address',
            name: teamMail.address,
            key: SubjectAbility.TEAM_MAIL_ADDRESS,
          },
        ],
      },
      {
        key: SubjectAbility.APPROVAL,
        name: approval.approval,
        refresh: true,
        children: [
          { path: '/approval/my-draft', name: approval.myDraft, key: SubjectAbility.MY_DRAFT },
          {
            path: '/approval/for-approval',
            name: approval.forApproval,
            key: SubjectAbility.FOR_APPROVAL,
          },
          {
            path: '/approval/in-progress',
            name: approval.inProgress,
            key: SubjectAbility.IN_PROGRESS,
          },
          { path: '/approval/approved', name: approval.approved, key: SubjectAbility.APPROVED },
          { path: '/approval/rejected', name: approval.rejected, key: SubjectAbility.REJECTED },
          { path: '/approval/reference', name: approval.reference, key: SubjectAbility.REFERENCE },
          { path: '/approval/signature', name: approval.signature, key: SubjectAbility.SIGNATURE },
        ],
      },
    ],
  },
  {
    key: SubjectAbility.PROCUREMENT,
    icon: shallowRef(ProcurementIcon),
    current: false,
    name: 'procurement',
    children: [
      {
        key: SubjectAbility.PROCUREMENT_REQUISITION,
        name: 'requisition',
        children: [
          {
            key: SubjectAbility.PROCUREMENT_REQUISITION_SPARE,
            path: '/procurement/requisition/spare',
            name: 'requisitionSpare',
          },
          {
            key: SubjectAbility.PROCUREMENT_REQUISITION_STORE,
            name: 'requisitionStore',
            path: '/procurement/requisition/store',
          },
          {
            key: SubjectAbility.PROCUREMENT_REQUISITION_OIL,
            path: '/procurement/requisition/oil',
            name: 'requisitionOil',
          },
        ],
      },
      {
        key: SubjectAbility.PROCUREMENT_PROCESS_MONITOR,
        name: 'processMonitor',
        path: '/procurement/process-monitor',
      },
      {
        key: SubjectAbility.PROCUREMENT_E_SUPPLY_PORTAL,
        name: 'eSupplyPortal',
        children: [
          {
            path: '/procurement/e-supply-portal/e-purchase/quotation',
            name: 'quotation',
            key: SubjectAbility.PROCUREMENT_E_SUPPLY_PORTAL_QUOTATION,
          },
          {
            name: 'order',
            path: '/procurement/e-supply-portal/e-purchase/order',
            key: SubjectAbility.PROCUREMENT_E_SUPPLY_PORTAL_ORDER,
          },
          {
            path: '/procurement/e-supply-portal/e-purchase/good-receipt',
            name: 'goodReceipt',
            key: SubjectAbility.PROCUREMENT_E_SUPPLY_PORTAL_GOOD_RECEIPT,
          },
        ],
      },
      {
        key: SubjectAbility.PROCUREMENT_VESSEL_BUDGET,
        path: '/procurement/vessel-budget/vessel-budget',
        name: 'vesselBudget',
      },
      {
        key: SubjectAbility.PROCUREMENT_MASTER_DATA,
        name: 'masterData',
        children: [
          {
            key: SubjectAbility.PROCUREMENT_MASTER_DATA_SPARE_PART_REGISTRATION,
            path: '/procurement/master-data/spare-part-registration',
            name: 'sparePartRegistration',
          },
          {
            key: SubjectAbility.PROCUREMENT_MASTER_DATA_STORE_REGISTRATION,
            path: '/procurement/master-data/store-registration',
            name: 'storeRegistration',
          },
          {
            key: SubjectAbility.PROCUREMENT_MASTER_DATA_SPARE_PART_CODE,
            path: '/procurement/master-data/spare-part-code',
            name: 'sparePartCode',
          },
          {
            key: SubjectAbility.PROCUREMENT_MASTER_DATA_STORE_CODE,
            path: '/procurement/master-data/store-code',
            name: 'storeCode',
          },
        ],
      },
      {
        key: SubjectAbility.EWMS_MOVEMENT_ORDER,
        name: '',
        children: [
          {
            key: SubjectAbility.EWMS_MOVEMENT_ORDER_SUPPLY,
            path: '/ewms/movement-order/movement-plan-supply',
            name: 'movementPlanSupply',
          },
          {
            key: SubjectAbility.EWMS_MOVEMENT_ORDER_VESSEL,
            path: '/ewms/movement-order/movement-plan-vessel',
            name: 'movementPlanVessel',
          },
        ],
      },
    ],
  },
  {
    key: SubjectAbility.ADMINISTRATION,
    name: 'administration',
    icon: shallowRef(VOIcon),
    current: false,
    children: [
      { path: '/administration/user-manage', name: 'userManage', key: SubjectAbility.USER_MANAGE },
      { path: '/administration/menu-list', name: 'menuList', key: SubjectAbility.MENU_LIST },
    ],
  },
]
