import { ActionsAbility } from 'shared/types/ability'
import { KainosMessageByModule } from 'shared/utils/constants'
import layouts from 'shared/utils/layout'
import { createRouter, createWebHistory } from 'vue-router'

import Forbidden from 'ui/src/Forbidden.vue'
import NotFound from 'ui/src/NotFound.vue'

import { hasPermission } from '@/core/ability'
import { setI18nMessage } from '@/locales'
import { useAuthStore } from '@/stores/auth'
import { useMainStore } from '@/stores/main'

import EnterNewPasswordPopup from '../views/pages/authentication/EnterNewPasswordPopup.vue'
import Login from '../views/pages/authentication/LoginView.vue'
// Main app
import MainPage from '../views/pages/MainPage.vue'
import boardRoutes from './hnvs-mf-board'
import eproBidRoutes from './hnvs-mf-epro-bid'
import eProPayStatusRoutes from './hnvs-mf-epro-pay-status'
import epurchaseRoutes from './hnvs-mf-epro-purchase'
import eproVesselInfoRoutes from './hnvs-mf-epro-vessel-info'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...epurchaseRoutes,
    ...eproBidRoutes,
    ...eproVesselInfoRoutes,
    ...eProPayStatusRoutes,
    ...boardRoutes,

    // Main app
    {
      path: '/',
      alias: '/dashboard',
      name: 'Dashboard',
      component: MainPage,
      meta: {
        auth: true,
        layout: layouts.navLeft,
        title: 'dashboard',
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        layout: layouts.contenOnly,
      },
    },
    {
      path: '/login/:catchAll(.*)',
      name: 'LoginCatchAll',
      component: Login,
      meta: {
        layout: layouts.contenOnly,
      },
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: EnterNewPasswordPopup,
      props: (route) => ({
        token: route.query.token,
        visible: true,
      }),
    },
    {
      path: '/logout',
      redirect: () => {
        useAuthStore().setLogout()
        return '/login'
      },
    },
    {
      path: '/',
      name: 'dashboard',
      component: () => import('../views/pages/Demo.vue'),
    },

    //  ------------------------ ePro ------------------------
    {
      path: '/epro/main/contact',
      name: 'eProContact',
      component: () => import('../views/pages/e-pro/Contact.vue'),
      meta: {
        layout: layouts.navLeft,
        auth: false,
        permission: false,
        title: 'contactUs',
        isSpecial: true,
        breadCrumbs: [
          { text: 'ePro' },
          { text: 'contactUs' },
          { text: 'contactUs', isActive: true },
        ],
      },
    },
    {
      path: '/epro/main/location',
      name: 'eProLocaiton',
      component: () => import('../views/pages/e-pro/Location.vue'),
      meta: {
        layout: layouts.navLeft,
        auth: false,
        permission: false,
        title: 'location',
        isSpecial: true,
        breadCrumbs: [
          { text: 'ePro' },
          { text: 'contactUs' },
          { text: 'location', isActive: true },
        ],
      },
    },

    //  ------------------------ /* About-Us */ ------------------------
    {
      path: '/hmm-introduction/directions',
      name: 'HMM 찾아오시는길',
      redirect: () => {
        return '/epro/main/location'
      },
    },
    {
      path: '/hos-introduction/directions',
      name: 'HOS 찾아오시는길',
      redirect: () => {
        return '/epro/main/location'
      },
    },

    {
      path: '/403',
      name: 'Forbidden',
      component: Forbidden,
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: NotFound,
      meta: {
        layout: layouts.contenOnly,
        title: '',
      },
    },
  ],
})

router.beforeEach(async (to) => {
  const auth = useAuthStore()
  if (to.meta.auth) {
    if (auth.isLogged) {
      if (to.name === 'Login') {
        window.location.href = '/'
        return false
      }
    } else if (to.name !== 'Login' && to.name !== 'ResetPassword') {
      window.location.href = '/login'
      return false
    }
  } else if (
    auth.isLogged &&
    (to.name === 'Login' || to.name === 'Register' || to.name === 'ResetPassword')
  ) {
    window.location.href = '/'
    return false
  }

  if (to.meta.permission && !hasPermission(ActionsAbility.VISIBLE, to.name.toString())) {
    window.location.href = '/403'
    return false
  }
})

router.afterEach((to) => {
  if (to.meta.layout) {
    useMainStore().setLayout(to.meta.layout)
  }
  setI18nMessage(to.meta.locales ? (to.meta.locales as KainosMessageByModule[]) : [])
  setTimeout(() => {
    useMainStore().setSplashScreen(false)
  }, 250)
})

export default router
