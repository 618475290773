import { SubjectAbility } from 'shared/types/ability'
import { defineAsyncComponentWithOptions } from 'shared/utils'
import { KainosMessageByModule } from 'shared/utils/constants'
import layouts from 'shared/utils/layout'

const Quotation = defineAsyncComponentWithOptions(() => import('epurchase/Quotation.vue'))
const Order = defineAsyncComponentWithOptions(() => import('epurchase/Order.vue'))
const GoodReceipt = defineAsyncComponentWithOptions(() => import('epurchase/GoodReceipt.vue'))
const Invoice = defineAsyncComponentWithOptions(() => import('epurchase/Invoice.vue'))
const Claim = defineAsyncComponentWithOptions(() => import('epurchase/Claim.vue'))

const epurchaseRoutes = [
  {
    path: '/procurement/e-supply-portal/e-purchase/quotation',
    name: SubjectAbility.PROCUREMENT_E_SUPPLY_PORTAL_QUOTATION,
    component: Quotation,
    meta: {
      layout: layouts.navLeft,
      auth: true,
      title: 'quotationTitle',
      locales: [KainosMessageByModule.PROCUREMENT],
      isSpecial: true,
      breadCrumbs: [{ text: 'ePro' }, { text: 'ePurchase' }, { text: 'quotation', isActive: true }],
    },
  },
  {
    path: '/procurement/e-supply-portal/e-purchase/order',
    name: SubjectAbility.PROCUREMENT_E_SUPPLY_PORTAL_ORDER,
    component: Order,
    meta: {
      auth: true,
      layout: layouts.navLeft,
      title: 'orderDeliveryTitle',
      locales: [KainosMessageByModule.PROCUREMENT],
      isSpecial: true,
      breadCrumbs: [{ text: 'ePro' }, { text: 'ePurchase' }, { text: 'order', isActive: true }],
    },
  },
  {
    path: '/procurement/e-supply-portal/e-purchase/good-receipt',
    name: SubjectAbility.PROCUREMENT_E_SUPPLY_PORTAL_GOOD_RECEIPT,
    component: GoodReceipt,
    meta: {
      layout: layouts.navLeft,
      auth: true,
      title: 'goodReceiptList',
      locales: [KainosMessageByModule.PROCUREMENT],
      tags: ['home'],
      isSpecial: true,
      breadCrumbs: [
        { text: 'ePro' },
        { text: 'ePurchase' },
        { text: 'goodReceipt', isActive: true },
      ],
    },
  },
  {
    path: '/procurement/e-supply-portal/e-purchase/invoice',
    name: SubjectAbility.PROCUREMENT_E_SUPPLY_PORTAL_INVOICE,
    component: Invoice,
    meta: {
      layout: layouts.navLeft,
      auth: true,
      title: 'invoice',
      locales: [KainosMessageByModule.EPRO],
      tags: ['home'],
      breadCrumbs: [{ text: 'ePro' }, { text: 'ePurchase' }, { text: 'invoice', isActive: true }],
    },
  },
  {
    path: '/procurement/e-supply-portal/e-purchase/claimList',
    name: SubjectAbility.PROCUREMENT_E_SUPPLY_PORTAL_CLAIM_LIST,
    component: Claim,
    meta: {
      layout: layouts.navLeft,
      auth: true,
      title: 'claim',
      locales: [KainosMessageByModule.EPRO],
      tags: ['home'],
      breadCrumbs: [{ text: 'ePro' }, { text: 'ePurchase' }, { text: 'claim', isActive: true }],
    },
  },
]

export default epurchaseRoutes
