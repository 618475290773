import { SubjectAbility } from 'shared/types/ability'
import { defineAsyncComponentWithOptions } from 'shared/utils'
import { KainosMessageByModule } from 'shared/utils/constants'
import layouts from 'shared/utils/layout'

const PaymentStatus = defineAsyncComponentWithOptions(
  () => import('eproPayStatus/PaymentStatus.vue')
)

const eproPayStatusRoutes = [
  {
    path: '/procurement/e-supply-portal/paymentStatus',
    name: SubjectAbility.PROCUREMENT_E_SUPPLY_PORTAL_PAYMENT_STATUS,
    component: PaymentStatus,
    meta: {
      layout: layouts.navLeft,
      auth: true,
      title: 'paymentStatus',
      locales: [KainosMessageByModule.EPRO],
      tags: ['home'],
      breadCrumbs: [
        { text: 'ePro' },
        { text: 'paymentStatus' },
        { text: 'paymentStatus', isActive: true },
      ],
    },
  },
]

export default eproPayStatusRoutes
