import { ref } from 'vue'

import type { Menu } from './type'

export const menuItemSample = ref<Menu[]>([
  // {
  //   number: 0,
  //   name: 'dashboard',
  //   koreanName: '',
  //   level: 'LEVEL1',
  //   icon: {},
  //   current: false,
  //   path: '/',
  //   parentNumber: null,
  // },
  {
    key: 'procurement-e-supply-portal',
    number: 100,
    path: '',
    name: 'E-Purchase',
    koreanName: 'E-Purchase',
    level: 'LEVEL1',
    icon: {},
    current: false,
    parentNumber: null,
    children: [
      {
        key: 'procurement-e-supply-portal-quotation',
        number: 101,
        path: '/procurement/e-supply-portal/e-purchase/quotation',
        name: 'Quotation',
        koreanName: '',
        level: 'LEVEL2',
        parentNumber: 10,
        children: [],
        isVisible: true,
        isPrintable: true,
        isRetrieval: true,
        isEditable: false,
        isDeletable: false,
        isAdmin: false,
      },
      {
        key: 'procurement-e-supply-portal-order',
        number: 102,
        path: '/procurement/e-supply-portal/e-purchase/order',
        name: 'Order',
        koreanName: '',
        level: 'LEVEL2',
        parentNumber: 100,
        children: [],
        isVisible: true,
        isPrintable: true,
        isRetrieval: true,
        isEditable: false,
        isDeletable: false,
        isAdmin: false,
      },
      {
        key: 'procurement-e-supply-portal-good-receipt',
        number: 103,
        path: '/procurement/e-supply-portal/e-purchase/good-receipt',
        name: 'Good Receipt',
        koreanName: '',
        level: 'LEVEL2',
        parentNumber: 100,
        children: [],
        isVisible: true,
        isPrintable: true,
        isRetrieval: true,
        isEditable: false,
        isDeletable: false,
        isAdmin: false,
      },
      {
        key: 'procurement-e-supply-portal-invoice',
        number: 104,
        path: '/procurement/e-supply-portal/e-purchase/invoice',
        name: 'Invoice List',
        koreanName: 'Invoice List',
        level: 'LEVEL2',
        parentNumber: 100,
        children: [],
        isVisible: true,
        isPrintable: true,
        isRetrieval: true,
        isEditable: false,
        isDeletable: false,
        isAdmin: false,
      },
      {
        key: 'procurement-e-supply-portal-claim-list',
        number: 105,
        path: '/procurement/e-supply-portal/e-purchase/claimList',
        name: 'Claim List',
        koreanName: 'Claim List',
        level: 'LEVEL2',
        parentNumber: 100,
        children: [],
        isVisible: true,
        isPrintable: true,
        isRetrieval: true,
        isEditable: false,
        isDeletable: false,
        isAdmin: false,
      },
    ],
    isVisible: true,
    isPrintable: true,
    isRetrieval: true,
    isEditable: false,
    isDeletable: false,
    isAdmin: false,
  },
  {
    key: 'eproBid',
    number: 200,
    path: '',
    name: 'E-Bid',
    koreanName: 'E-Bid',
    level: 'LEVEL1',
    icon: {},
    current: false,
    parentNumber: null,
    children: [
      {
        key: 'epro-bid-supplier-bid-list',
        number: 201,
        path: '/epro/bid/supplier/bid-list',
        name: 'E-Bid',
        koreanName: 'E-Bid',
        level: 'LEVEL2',
        icon: {},
        current: false,
        parentNumber: 200,
        children: [],
        isVisible: true,
        isPrintable: true,
        isRetrieval: true,
        isEditable: false,
        isDeletable: false,
        isAdmin: false,
      },
    ],
  },
  {
    key: 'paymentStatus',
    number: 300,
    path: '',
    name: 'Payment Status',
    koreanName: 'Payment Status',
    level: 'LEVEL1',
    icon: {},
    current: false,
    parentNumber: null,
    children: [
      {
        key: 'procurement-e-supply-portal-payment-status',
        number: 301,
        path: '/procurement/e-supply-portal/paymentStatus',
        name: 'Payment Status List',
        koreanName: 'Payment Status List',
        level: 'LEVEL2',
        parentNumber: 300,
        children: [],
        isVisible: true,
        isPrintable: true,
        isRetrieval: true,
        isEditable: false,
        isDeletable: false,
        isAdmin: false,
      },
    ],
  },
  {
    key: 'contact',
    number: 400,
    name: 'Contact Us',
    koreanName: '',
    level: 'LEVEL1',
    icon: {},
    current: false,
    path: '',
    parentNumber: null,
    children: [
      {
        key: 'contact-us',
        number: 401,
        path: '/epro/main/contact',
        name: 'Contact Us',
        koreanName: '',
        level: 'LEVEL2',
        parentNumber: 400,
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
      {
        key: 'e-pro-location',
        number: 402,
        path: '/epro/main/location',
        name: 'Location',
        koreanName: '',
        level: 'LEVEL2',
        icon: {},
        parentNumber: 400,
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
    ],
  },
  {
    key: 'vesselInfo',
    number: 500,
    path: '',
    name: 'Vessel Information',
    koreanName: 'Vessel Information',
    level: 'LEVEL1',
    icon: {},
    current: false,
    parentNumber: null,
    children: [
      {
        key: 'vesselInfoList',
        number: 501,
        path: '/epro/vessel-info/vessel-info-list',
        name: 'Vessel Information List',
        koreanName: 'Vessel Information List',
        level: 'LEVEL2',
        parentNumber: 500,
        children: [],
        isVisible: true,
        isPrintable: true,
        isRetrieval: true,
        isEditable: false,
        isDeletable: false,
        isAdmin: false,
      },
    ],
  },
  {
    key: 'support',
    number: 600,
    name: 'Support',
    koreanName: '',
    level: 'LEVEL1',
    icon: {},
    current: false,
    path: '',
    parentNumber: null,
    children: [
      {
        key: 'administration-epro-board-news',
        number: 601,
        path: '/support/board/notice',
        name: 'Notice',
        koreanName: '',
        level: 'LEVEL2',
        parentNumber: 600,
        children: [],
        isVisible: true,
        isPrintable: true,
        isRetrieval: true,
        isEditable: false,
        isDeletable: false,
        isAdmin: false,
      },
      {
        key: 'administration-epro-board-qa',
        number: 602,
        path: '/support/board/qa',
        name: 'QA',
        koreanName: '',
        level: 'LEVEL2',
        parentNumber: 600,
        children: [],
        isVisible: true,
        isPrintable: true,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: false,
      },
      {
        key: 'administration-epro-board-faq',
        number: 603,
        path: '/support/board/faq',
        name: 'FAQ',
        koreanName: '',
        level: 'LEVEL2',
        parentNumber: 600,
        children: [],
        isVisible: true,
        isPrintable: true,
        isRetrieval: true,
        isEditable: false,
        isDeletable: false,
        isAdmin: false,
      },
    ],
  },
  // ##########################################################
  // Please do not delete it. (start)
  // ##########################################################
  {
    key: 'hmm-introduction',
    number: 30,
    name: 'HMM 소개',
    koreanName: 'HMM 소개',
    level: 'LEVEL1',
    current: false,
    icon: {},
    path: '',
    parentNumber: null,
    children: [
      {
        key: 'hmm-introduction-about-us',
        number: 31,
        name: '회사소개',
        koreanName: '회사소개',
        level: 'LEVEL2',
        parentNumber: 30,
        path: 'https://www.hmm21.com/company/aboutUs.do',
        icon: {},
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
      {
        key: 'hmm-introduction-ceo-message',
        number: 32,
        name: 'CEO 인사말',
        koreanName: 'CEO 인사말',
        level: 'LEVEL2',
        parentNumber: 30,
        path: 'https://www.hmm21.com/company/ceoGreeting.do',
        icon: {},
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
      {
        key: 'hmm-introduction-history',
        number: 33,
        name: '회사연혁',
        koreanName: '회사연혁',
        level: 'LEVEL2',
        parentNumber: 30,
        path: 'https://www.hmm21.com/company/history.do',
        icon: {},
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
      {
        key: 'hmm-introduction-promotional-video',
        number: 34,
        name: '홍보동영상',
        koreanName: '홍보동영상',
        level: 'LEVEL2',
        parentNumber: 30,
        path: 'https://www.youtube.com/watch?v=soUrMKdkVoM',
        icon: {},
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
      {
        key: 'hmm-introduction-image-gallery',
        number: 35,
        name: '이미지 갤러리',
        koreanName: '이미지 갤러리',
        level: 'LEVEL2',
        parentNumber: 30,
        path: 'https://www.hmm21.com/company/media/gallery.do',
        icon: {},
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
      {
        key: 'hmm-introduction-directions',
        number: 36,
        name: '찾아오시는길',
        koreanName: '찾아오시는길',
        level: 'LEVEL2',
        parentNumber: 30,
        path: 'https://www.hmm21.com/company/contactUs/contactUsDetail.do?goTpCd=C&goRegion=East%20Asia&goArea=KR&cityNm=Seoul&siteTp=C',
        icon: {},
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
    ],
    isVisible: true,
    isPrintable: false,
    isRetrieval: true,
    isEditable: true,
    isDeletable: true,
    isAdmin: true,
  },
  {
    key: 'hmm-introduction-ethics-hotline',
    number: 37,
    name: '사이버제보실',
    koreanName: '사이버제보실',
    level: 'LEVEL1',
    parentNumber: null,
    path: 'https://www.hmm21.com/ethics/informantReg.do?lang=en',
    icon: {},
    children: [],
    isVisible: true,
    isPrintable: false,
    isRetrieval: true,
    isEditable: true,
    isDeletable: true,
    isAdmin: true,
  },
  {
    key: 'hos-introduction',
    number: 40,
    name: 'HOS 소개',
    koreanName: 'HOS 소개',
    level: 'LEVEL1',
    current: false,
    icon: {},
    path: '',
    parentNumber: null,
    children: [
      {
        key: 'hos-introduction-about-us',
        number: 41,
        name: '회사소개',
        koreanName: '회사소개',
        level: 'LEVEL2',
        parentNumber: 30,
        path: 'https://hos21.com/about/company.aspx',
        icon: {},
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
      {
        key: 'hos-introduction-ceo-message',
        number: 42,
        name: 'CEO 인사말',
        koreanName: 'CEO 인사말',
        level: 'LEVEL2',
        parentNumber: 40,
        path: 'https://hos21.com/about/ceo.aspx',
        icon: {},
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
      {
        key: 'hos-introduction-history',
        number: 43,
        name: '회사연혁',
        koreanName: '회사연혁',
        level: 'LEVEL2',
        parentNumber: 40,
        path: 'https://hos21.com/about/history.aspx',
        icon: {},
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
      {
        key: 'hos-introduction-promotional-video',
        number: 44,
        name: '홍보동영상',
        koreanName: '홍보동영상',
        level: 'LEVEL2',
        parentNumber: 40,
        path: 'https://hos21.com/prcenter/pr1.aspx',
        icon: {},
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
      {
        key: 'hos-introduction-image-gallery',
        number: 45,
        name: '이미지 갤러리',
        koreanName: '이미지 갤러리',
        level: 'LEVEL2',
        parentNumber: 40,
        path: 'https://hos21.com/prcenter/newsList.aspx',
        icon: {},
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
      {
        key: 'hos-introduction-directions',
        number: 46,
        name: '찾아오시는길',
        koreanName: '찾아오시는길',
        level: 'LEVEL2',
        parentNumber: 40,
        path: 'https://hos21.com/about/location.aspx',
        icon: {},
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
    ],
    isVisible: true,
    isPrintable: false,
    isRetrieval: true,
    isEditable: true,
    isDeletable: true,
    isAdmin: true,
  },
  // ##########################################################
  // Please do not delete it. (end)
  // ##########################################################
])
