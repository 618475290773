<template>
  <el-dialog v-model="isVisible" class="!min-w-[550px] !max-w-[650px]" @close="handleClose">
    <template #header>
      <div class="custom-dialog-header">
        {{ $t('common.labels.searchId') }}
      </div>
    </template>
    <content-layout>
      <template #content-header>
        <p>{{ '* ' + $t('common.labels.businessWarning') + ' -' }}</p>

        <el-descriptions :column="1" border class="overflow-x-auto">
          <el-descriptions-item
            :label="$t('common.labels.businessRegistration')"
            min-width="50"
            label-class-name="bg-primary-lighter"
            label-align="right"
          >
            <div class="flex justify-between gap-1">
              <el-input
                v-model="businessRegistrationSearch"
                type="text"
                class="flex-1 mr-2 custom-input"
                :placeholder="$t('common.placeholders.businessRegistration')"
                @keyup.enter="handleSearch"
              />
              <el-button type="primary" @click="handleSearch">
                {{ $t('common.buttons.search') }}
              </el-button>
            </div>
          </el-descriptions-item>
          <el-descriptions-item
            :label="$t('common.labels.userId')"
            min-width="50"
            label-class-name="bg-primary-lighter"
            label-align="right"
          >
            <el-input
              v-model="userID"
              type="text"
              class="flex-1 mr-2 custom-input background"
              :placeholder="$t('common.placeholders.userId')"
            />
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </content-layout>
    <template #footer>
      <el-button type="secondary" @click="handleClose">{{ $t('common.buttons.close') }} </el-button>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

import { useAuthStore } from '@/stores/auth'
import { useMainStore } from '@/stores/main'

// Props
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
})

// Emits
const emit = defineEmits(['submit', 'close'])

// Refs
const isVisible = ref<boolean>(false)
const businessRegistrationSearch = ref<string>('')
const userID = ref<string>('')

// Watch
watch(
  () => props.visible,
  (newVal) => {
    isVisible.value = newVal
  }
)

// Functions
const emitEvents = () => {
  emit('submit')
  emit('close')
}

const handleClose = () => {
  emitEvents()
}

const handleSearch = () => {
  useMainStore().showLoading()
  useAuthStore()
    .searchID(businessRegistrationSearch.value)
    .then((res) => {
      userID.value = res
    })
    .catch(() => {})
    .finally(() => {
      useMainStore().hideLoading()
    })
}
</script>

<style lang="scss" scoped>
/* Custom styles for input and select components */
:deep(.custom-input .el-input__wrapper) {
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.custom-input {
  &.background {
    .el-input__wrapper {
      background-color: #d9d9d9;
    }
  }
}
</style>
