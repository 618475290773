import { endpoints } from 'shared'

import { reqGET } from '@/shared/apis/api-client'

class BoardService {
  getBoardDetail(params: { boardSequence: number; isConfidential: boolean }) {
    return reqGET(`${endpoints.common.boardManagement}/${params.boardSequence}`, params)
  }
}

const boardService = new BoardService()

export default boardService
