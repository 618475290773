export enum PROFILE_TAB_ID {
  COMPANY_DETAIL = 'companyDetail',
  PERSONAL_DETAIL = 'personalDetail',
  SALES_PERSON = 'salesPerson',
}

export enum DEFAULT_VALUE {
  ALL = 'all',
  VESSEL_CODE = 'HHCR',
  COMPANY_CODE = 'HMM',
  VOYAGE_CODE = '0029W',
  DOC_NO = '21000528',
  EMPTY = 'Empty',
}

export interface CompanyDetailForm {
  supplierCode: string
  supplierName: string
  business: string
  businessType: string
  businessKind: string
  businessItem: string
  certificateResident: string
  nation: string
  state: string
  city: string
  locationCode: string
  zipCode: string
  address: string
  yearRevenue: string
  totalEmployee: string
  majorTradingPartner: string
  supplierItem: SupplierItem
}

interface SupplierItem {
  supplierItemFirst: string
  supplierItemSecond: string
  supplierItemThird: string
}

export interface PersonalDetailForm {
  representativeName: string
  representativeNameLocal: string
  residentRegistrationNo: string
  representativeTel: string
  representativeFax: string
  representativeMobile: string
  representativeEmail: string
  supplierNameLocal: string
  addressLocal: string
}

export interface SalespersonForm {
  salesPersonNameFirst: string
  salesPersonTelFirst: string
  salesPersonFaxFirst: string
  salesPersonMobileFirst: string
  salesPersonEmailFirst: string
  salesPersonNameSecond: string
  salesPersonTelSecond: string
  salesPersonFaxSecond: string
  salesPersonMobileSecond: string
  salesPersonEmailSecond: string
}
