import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  viewBox: "0 0 23.874 23.578"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("defs", null, [
      _createElementVNode("linearGradient", {
        id: "svg-linear-gradient",
        x1: "0.258",
        y1: "0.125",
        x2: "0.802",
        y2: "1",
        gradientUnits: "objectBoundingBox"
      }, [
        _createElementVNode("stop", {
          offset: "0",
          "stop-color": "#f9ae00"
        }),
        _createElementVNode("stop", {
          offset: "1",
          "stop-color": "#dd3200"
        })
      ])
    ], -1),
    _createElementVNode("path", {
      fill: "url(#svg-linear-gradient)",
      id: "svg-favorite-list-svgrepo-com_1_",
      "data-name": "favorite-list-svgrepo-com (1)",
      d: "M42.657,70.487l-5.138,2.773a1.089,1.089,0,0,1-.664.14,1.294,1.294,0,0,1-.647-.246,1.226,1.226,0,0,1-.425-.562,1.515,1.515,0,0,1-.1-.7l.987-5.9a1.145,1.145,0,0,0-.34-1.123l-4.152-4.177a1.157,1.157,0,0,1-.357-.614,1.293,1.293,0,0,1,.034-.72,1.394,1.394,0,0,1,.391-.6,1.06,1.06,0,0,1,.613-.281l5.751-.878a1.076,1.076,0,0,0,.919-.7l2.586-5.336a1.09,1.09,0,0,1,.442-.544,1.237,1.237,0,0,1,1.327,0,1.292,1.292,0,0,1,.476.544L46.911,56.9a1.152,1.152,0,0,0,.953.7l5.717.878a1.092,1.092,0,0,1,.647.281,1.265,1.265,0,0,1,.374.6,1.4,1.4,0,0,1,.017.72,1.273,1.273,0,0,1-.323.614l-4.152,4.177a1.145,1.145,0,0,0-.34,1.123l.953,5.9a1.249,1.249,0,0,1-.068.7,1.226,1.226,0,0,1-.425.562,1.3,1.3,0,0,1-.647.246,1.232,1.232,0,0,1-.7-.14l-5.1-2.773A1.138,1.138,0,0,0,42.657,70.487Z",
      transform: "translate(-31.283 -50.329)",
      stroke: "#ca3c3a",
      "stroke-width": "1",
      opacity: "0.56"
    }, null, -1)
  ])))
}
export default { render: render }