<template>
  <el-dialog
    v-model="isVisible"
    class="!min-w-[850px] !max-w-[950px] customLoginDialog"
    @close="handleClose"
  >
    <template #header>
      <div
        class="custom-dialog-header"
        v-if="localLang === languageTypeCode.EN"
        @click="handleToggleLang"
      >
        <span class="w-2/3">THE HMM SUSTAINABLE PROCUREMENT GUIDELINES</span>
        <span class="w-1/3 flex items-center justify-end cursor-pointer mr-7"
          ><globalIcon /> <span class="uppercase text-[12px] ml-2">Korean</span>
        </span>
      </div>
      <div class="custom-dialog-header" v-else @click="handleToggleLang">
        <span class="w-2/3">HMM 지속가능 구매정책</span>
        <span class="w-1/3 flex items-center justify-end cursor-pointer mr-7"
          ><globalIcon /> <span class="uppercase text-[12px] ml-2">English</span>
        </span>
      </div>
    </template>
    <div class="text-[14px] font-bold mb-5">
      <p class="flex items-start mb-3">
        <span class="mr-3"><guideline /></span>
        <span class="break-words" v-if="localLang === languageTypeCode.EN">
          As a global company, HMM is thoroughly engaged in efforts to manage sustainability risks
          in the supply chain network. We aim to boost the value of the supply chain by making
          responsible purchasing decisions and focusing on the long term. In consideration of these
          factors, HMM has established guidelines for sustainability with suppliers in the HMM
          Sustainable Procurement Guidelines, and requests for implementation of each partner
          according to these guidelines.
        </span>
        <span v-else>
          HMM은 글로벌 기업으로서 공급망의 지속가능성 위험을 최소화하기 위해 노력하고 있습니다.
          책임있는 구매 결정과 지속가능성 확산을 통해 공급망의 가치를 높이고자 합니다. 이러한 요소를
          고려하여 HMM은 HMM Sustainable Procurement Guidelines에서 협력사와의 지속가능을 위한
          지침을 수립 하였으며, 이 Guidelines에 따라 각 협력사의 이행을 요청 드립니다.
        </span>
      </p>
    </div>
    <div class="flex justify-end">
      <el-button type="primary">{{
        localLang === languageTypeCode.EN
          ? 'The HMM Sustainable Procurement Guidelines.pdf'
          : 'HMM_지속가능_구매_정책.pdf'
      }}</el-button>
    </div>
    <template #footer>
      <el-button type="secondary" @click="handleClose">{{ $t('common.buttons.close') }}</el-button>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref, shallowRef, watch } from 'vue'
import { languageTypeCode } from 'shared/types'

import Guideline from '@/components/icons/Guideline.vue'

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  lang: {
    type: String,
    required: true,
    default: languageTypeCode.EN,
  },
})

// Emits
const emit = defineEmits(['submit', 'close'])

// Refs
const isVisible = ref<boolean>(false)
const localLang = ref<string>(props.lang)

// Icons
const guideline = shallowRef(Guideline)

// Watch
watch(
  () => props.visible,
  (newVal) => {
    isVisible.value = newVal
  }
)

watch(
  () => props.lang,
  (newLang) => {
    localLang.value = newLang
  }
)

// Functions
const emitEvents = () => {
  emit('submit')
  emit('close')
}

const handleClose = () => {
  emitEvents()
}

const handleToggleLang = () => {
  localLang.value =
    localLang.value === languageTypeCode.EN ? languageTypeCode.KR : languageTypeCode.EN
}
</script>

<style lang="scss" scoped>
.footer-bg {
  background-color: #eaeaea;
  padding: 8px 15px;
}

.custom-dialog-header {
  font-size: 20px;
  font-weight: 900;
  line-height: 22px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
